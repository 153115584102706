const deskConfig5OG_A = [
    { id: '89', name: 'A001', coords: '98,40,50,100' },
    { id: '90', name: 'A002', coords: '98,140,50,100' },
    { id: '91', name: 'A003', coords: '148,40,50,100' },
    { id: '92', name: 'A004', coords: '148,140,50,100' },
    { id: '93', name: 'A005', coords: '345,35,50,100' },
    { id: '94', name: 'A006', coords: '345,135,50,100' },
    { id: '95', name: 'A007', coords: '400,65,50,100' },
    { id: '96', name: 'A008', coords: '400,165,50,100' },
    { id: '97', name: 'A009', coords: '600,30,50,100' },
    { id: '98', name: 'A010', coords: '600,130,50,100' },
    { id: '99', name: 'A011', coords: '650,30,50,100' },
    { id: '100', name: 'A012', coords: '650,130,50,100' },
    { id: '101', name: 'A021', coords: '900,70,55,100' },
    { id: '102', name: 'A022', coords: '950,70,55,100' },
    { id: '103', name: 'A017', coords: '915,575,50,100' },
    { id: '104', name: 'A018', coords: '910,675,50,100' },
    { id: '105', name: 'A019', coords: '965,605,50,100' },
    { id: '106', name: 'A020', coords: '965,700,50,100' },
    { id: '107', name: 'A013', coords: '630,600,50,100' },
    { id: '108', name: 'A014', coords: '630,700,50,100' },
    { id: '109', name: 'A015', coords: '680,600,50,100' },
    { id: '110', name: 'A016', coords: '680,700,50,100' }
]

export default deskConfig5OG_A