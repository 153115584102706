import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useMsGraphTokenContext } from './contexts/MsGraphTokenContextProvider';
import LoginPage from "./pages/login";
import Home from "./pages/home";
import BookingList from "./pages/booking-list";
import Overview from "./pages/overview";
import BookingOverview from "./pages/booking-overview";
import AdvancedBookingOverview from "./pages/booking-overview-advanced";
import NoPage from "./pages/NoPage";
import AdminSpecialBookingPage from "./pages/admin.special";
import ProtectedRoute from './utils/ProtectedRoute';
import Layout from "./pages/layout";
import 'bulma/css/bulma.min.css';


function AppContent() {
    const { instance, inProgress } = useMsal();
    const { isLoading, error, refreshToken } = useMsGraphTokenContext();
    // const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const handleRedirectPromise = async () => {
            try {
                console.log("Handling redirect promise");
                const response = await instance.handleRedirectPromise();
                if (response && response.account) {
                    console.log("Login successful, refreshing token");
                    await refreshToken();
                }
            } catch (error) {
                console.error("Error handling redirect:", error);
            }
        };

        if (inProgress === "none") {
            handleRedirectPromise();
        }
    }, [instance, inProgress, refreshToken]);


    if (isLoading) {
        return <div>Loading AppContent...</div>;
    }

    if (error) {
        return <div>Error in AppContent: {error}</div>;
    }

    return (
        <>

            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/app" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="booking-list" element={<BookingList />} />
                    <Route path="shareddesk" element={<Overview />} />
                    <Route path="booking-overview" element={<BookingOverview />} />
                    <Route path="booking-overview-advanced" element={<AdvancedBookingOverview />} />
                    <Route path="admin-booking" element={
                        <ProtectedRoute>
                            <AdminSpecialBookingPage />
                        </ProtectedRoute>
                    } />
                    <Route path="*" element={<NoPage />} />
                </Route>
                <Route path="*" element={<NoPage />} />
            </Routes>
        </>
    );
}

export default AppContent;
