const deskConfig5OG_C = [
    { id: '133', name: 'C003', coords: '590,1650,100,50' },
    { id: '134', name: 'C004', coords: '695,1655,100,50' },
    { id: '135', name: 'C001', coords: '590,1705,100,50' },
    { id: '136', name: 'C002', coords: '695,1710,100,50' },
    { id: '137', name: 'C007', coords: '590,975,100,50' },
    { id: '138', name: 'C008', coords: '695,985,100,50' },
    { id: '139', name: 'C005', coords: '590,1030,100,50' },
    { id: '140', name: 'C006', coords: '700,1035,100,50' },
    { id: '141', name: 'C011', coords: '560,485,100,50' },
    { id: '142', name: 'C012', coords: '660,485,100,50' },
    { id: '143', name: 'C009', coords: '565,535,100,50' },
    { id: '144', name: 'C010', coords: '665,540,100,50' },
    { id: '145', name: 'C014', coords: '440,60,50,100' },
    { id: '147', name: 'C015', coords: '495,60,50,100' },
    { id: '149', name: 'C018', coords: '25,390,100,50' },
    { id: '150', name: 'C019', coords: '125,400,100,50' },
    { id: '151', name: 'C016', coords: '60,445,100,50' },
    { id: '152', name: 'C017', coords: '160,450,100,50' }
]

export default deskConfig5OG_C