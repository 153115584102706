import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig } from "./authConfig";
import { ApiTokenProvider } from './contexts/ApiTokenProvider';
import { MsGraphTokenProvider } from './contexts/MsGraphTokenContextProvider';
import { UserProvider } from "./contexts/UserContext";
import AppContent from './AppContent';
import 'bulma/css/bulma.min.css';

const msalInstance = new PublicClientApplication(getMsalConfig());

export default function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <ApiTokenProvider>
                <UserProvider>
                    <MsGraphTokenProvider>
                        <AppContent />
                    </MsGraphTokenProvider>
                </UserProvider>
            </ApiTokenProvider>
        </MsalProvider>
    );
}
