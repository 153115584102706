import React, { useState, useEffect } from "react";
import { Button, Section } from "react-bulma-components";
import Calendar from "react-calendar";
import { useNavigate } from 'react-router-dom';
import BookingForm from "./BookingForm/bookingform.component";
import { useMsal } from "@azure/msal-react";
import useAxiosWithToken from '../hooks/axiosTokenHook';
import { formatDateISO, formatDateUI } from "../utils/utils";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FloorPlan from "../services/floorplan/FloorPlan";
import 'bulma/css/bulma.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';


const OverviewComponent = () => {
  const axios = useAxiosWithToken();

  const [isLoading, setIsLoading] = useState(false);
  const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const user = userAccount ? userAccount.username : "No User";

  const [bookings, setBookings] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [areas, setAreas] = useState([]);
  const navigate = useNavigate();
  const [actualDate, onChange] = useState(new Date());
  const [selectedFloor, setSelectedFloor] = useState('5');
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [areasResponse, bookingsResponse] = await Promise.all([
          axios.get("areas"),
          axios.get(`bookings?date=${formatDateISO(actualDate)}`)
        ]);

        setAreas(areasResponse.data);
        setBookings(bookingsResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [actualDate, axios, navigate]);

  const toggleFormSection = () => {
    setFormVisible(currentVisibility => !currentVisibility);
  };

  const handleAreaClick = (desk) => {
    let bookedDesks = new Set(bookings.filter(booking => booking.id).map(booking => String(booking.deskid)));
    const isBooked = bookedDesks.has(String(desk.id));
    let existingBooking = bookings
      .filter(booking => booking.username === user)
      .filter(booking => booking.date === formatDateISO(actualDate))
      .filter(booking => booking.isAdmin === 0) || [];

    if (isBooked) {
      confirmAlert({
        title: 'Attention!',
        message: `This desk is already booked.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    } else {
      if (existingBooking.length > 0) {
        existingBooking = existingBooking[0];
        confirmAlert({
          title: 'Reassigning desk!',
          message: `Reassigning desk from ${existingBooking.desktitle}. Do you want to book desk with name: ${desk.name} on the ${formatDateUI(actualDate)}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                try {
                  bookTable(existingBooking.id, desk.id);
                } catch (error) {
                  console.error('Error booking the desk:', error);
                }
              }
            },
            {
              label: 'No',
              onClick: () => { }
            }
          ]
        });
      } else {
        confirmAlert({
          title: 'Confirm Booking',
          message: `Do you want to book desk with name: ${desk.name} on the ${formatDateUI(actualDate)}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                try {
                  bookTable(null, desk.id);
                } catch (error) {
                  console.error('Error booking the desk:', error);
                }
              }
            },
            {
              label: 'No',
              onClick: () => { }
            }
          ]
        });
      }
    }
  };



  const bookTable = async (id, deskid) => {
    const date = formatDateISO(actualDate);
    const username = user;
    const lastModifiedBy = user;
    const bookedBy = user;

    try {
      if (id === undefined || id === null) {
        const result = await axios.post("bookings", { username, deskid, date, lastModifiedBy, bookedBy });
        if (result.status === 200) {
          const { data } = await axios.get(`bookings?date=${date}`);
          setBookings(data);
          setSelectedArea(null);  // Reset selected area
        } else {
          return Promise.reject();
        }
      } else {
        const result = await axios.put(`bookings/${id}`, { lastModifiedBy });
        if (result.status === 200 || result.status === 204) {
          await axios.delete(`bookings/${id}`);
          const postResult = await axios.post("bookings", { username, deskid, date, lastModifiedBy, bookedBy });
          if (postResult.status === 200) {
            const { data } = await axios.get(`bookings?date=${date}`);
            setBookings(data);
            setSelectedArea(null);  // Reset selected area
          } else {
            return Promise.reject();
          }
        } else {
          return Promise.reject();
        }
      }
    } catch (error) {
      confirmAlert({
        title: 'Attention! desk!',
        message: `Something went wrong confirming the booking.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      console.error('Error confirming the booking:', error);
    }
  };

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value);
  };

  const FloorDropdown = ({ selectedFloor, handleFloorChange }) => (
    <div className="columns is-vcentered" style={{ paddingTop: '20px' }}>
      <div className="column is-one-quarter has-text-left">
        <label className="label">Floor</label>
        <div className="select">
          <select value={selectedFloor} onChange={handleFloorChange}>
            <option value="5">5th Floor</option>
            <option value="6">6th Floor</option>
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <Section>
        <div className="columns">
          <div className="column is-offset-6 is-6">
            <div className="buttons are-small is-pulled-right" style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Button className="is-success">Available</Button>
              <Button className="is-danger">Unavailable</Button>
              <Button className="is-blue" style={{ backgroundColor: 'blue', color: 'white', border: 'none' }}>
                Your booking
              </Button>
              <Button style={{ backgroundColor: 'magenta', color: 'white' }}>Admin booking</Button>

            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="has-text-left" style={{ marginTop: '10px', marginBottom: '20px' }}>
          <Button className="button-custom" onClick={toggleFormSection}>
            Multiple bookings
          </Button>
        </div>

        {formVisible &&
          <div className="booking-form-container">
            <BookingForm key={formVisible} areas={areas} setFormVisible={setFormVisible} />
          </div>
        }
      </Section>
      <Section>
        <h1>Single booking</h1>

        <div className="columns is-vcentered" style={{ paddingTop: '20px' }}>
          <div className="column is-half is-offset-one-quarter has-text-centered">
            <Calendar onChange={onChange} value={actualDate} minDate={new Date()} />
          </div>
        </div>

        <FloorDropdown selectedFloor={selectedFloor} handleFloorChange={handleFloorChange} />

        <FloorPlan
          selectedFloor={selectedFloor}
          bookings={bookings}
          handleAreaClick={handleAreaClick}
          selectedArea={selectedArea}
          user={user}
          date={actualDate}
        />
      </Section>
    </>
  );
};

export default OverviewComponent;
