const deskConfig6OG = [
    { id: '1', name: 'D027', coords: '20,710,20,30' },
    { id: '2', name: 'D028', coords: '20,740,20,30' },
    { id: '3', name: 'D025', coords: '35,710,20,30' },
    { id: '4', name: 'D026', coords: '35,740,20,30' },
    { id: '7', name: 'D023', coords: '110,710,20,35' },
    { id: '8', name: 'D024', coords: '110,740,20,35' },
    { id: '9', name: 'D019', coords: '270,710,20,35' },
    { id: '10', name: 'D020', coords: '270,745,20,35' },
    { id: '11', name: 'D017', coords: '290,710,20,35' },
    { id: '12', name: 'D018', coords: '290,745,20,35' },
    { id: '13', name: 'D015', coords: '355,705,20,35' },
    { id: '14', name: 'D016', coords: '355,740,20,35' },
    { id: '15', name: 'D013', coords: '375,700,20,35' },
    { id: '16', name: 'D014', coords: '375,730,20,35' },
    { id: '17', name: 'D009', coords: '355,520,20,35' },
    { id: '18', name: 'D010', coords: '355,555,20,30' },
    { id: '19', name: 'D011', coords: '370,525,20,30' },
    { id: '20', name: 'D012', coords: '370,560,20,30' },
    { id: '21', name: 'D005', coords: '190,520,20,35' },
    { id: '22', name: 'D006', coords: '190,555,20,35' },
    { id: '23', name: 'D007', coords: '205,530,20,35' },
    { id: '24', name: 'D008', coords: '205,565,20,35' },
    { id: '25', name: 'D001', coords: '115,520,20,35' },
    { id: '26', name: 'D002', coords: '115,555,20,35' },
    { id: '27', name: 'D003', coords: '135,520,20,35' },
    { id: '28', name: 'D004', coords: '135,555,20,35' },
    { id: '31', name: 'E001', coords: '755,710,15,30' },
    { id: '32', name: 'E002', coords: '755,740,15,30' },
    { id: '33', name: 'E003', coords: '815,700,15,30' },
    { id: '34', name: 'E004', coords: '815,730,15,30' },
    { id: '35', name: 'E005', coords: '830,710,15,30' },
    { id: '36', name: 'E006', coords: '830,740,15,30' },
    { id: '37', name: 'E007', coords: '930,795,15,30' },
    { id: '38', name: 'E008', coords: '930,830,15,30' },
    { id: '39', name: 'E009', coords: '945,810,15,30' },
    { id: '40', name: 'E010', coords: '945,840,15,30' },
    { id: '41', name: 'E011', coords: '1010,810,20,30' },
    { id: '42', name: 'E012', coords: '1010,840,20,30' },
    { id: '43', name: 'E013', coords: '1030,810,20,30' },
    { id: '44', name: 'E014', coords: '1030,840,20,30' },
    { id: '45', name: 'E017', coords: '1000,730,35,15' },
    { id: '46', name: 'E018', coords: '1035,735,35,15' },
    { id: '47', name: 'E015', coords: '990,750,35,15' },
    { id: '48', name: 'E016', coords: '1025,750,35,15' },
    { id: '49', name: 'E021', coords: '1000,655,35,15' },
    { id: '50', name: 'E022', coords: '1030,655,35,15' },
    { id: '51', name: 'E019', coords: '1000,670,35,15' },
    { id: '52', name: 'E020', coords: '1030,670,35,15' },
    { id: '53', name: 'F003', coords: '1000,555,30,20' },
    { id: '54', name: 'F004', coords: '1035,560,30,20' },
    { id: '55', name: 'F001', coords: '1000,580,30,20' },
    { id: '56', name: 'F002', coords: '1035,575,30,20' },
    { id: '57', name: 'F007', coords: '1000,475,30,20' },
    { id: '58', name: 'F008', coords: '1035,475,30,20' },
    { id: '59', name: 'F005', coords: '1000,495,30,20' },
    { id: '60', name: 'F006', coords: '1035,495,30,20' },
    { id: '61', name: 'F011', coords: '990,285,30,20' },
    { id: '62', name: 'F012', coords: '1020,285,30,20' },
    { id: '63', name: 'F009', coords: '1000,300,30,20' },
    { id: '64', name: 'F010', coords: '1030,300,30,20' },
    { id: '65', name: 'F013', coords: '1000,210,30,20' },
    { id: '66', name: 'F014', coords: '1030,210,30,20' },
    { id: '67', name: 'F017', coords: '1000,135,30,20' },
    { id: '68', name: 'F018', coords: '1035,135,30,20' },
    { id: '69', name: 'F015', coords: '990,150,30,20' },
    { id: '70', name: 'F016', coords: '1020,150,30,20' },
    { id: '71', name: 'F021', coords: '1000,60,30,20' },
    { id: '72', name: 'F022', coords: '1030,55,30,20' },
    { id: '73', name: 'F019', coords: '1000,75,30,20' },
    { id: '74', name: 'F020', coords: '1035,75,30,20' },
    { id: '75', name: 'F023', coords: '810,135,30,20' },
    { id: '76', name: 'F024', coords: '845,135,30,20' },
    { id: '77', name: 'F025', coords: '820,150,30,20' },
    { id: '78', name: 'F026', coords: '855,155,30,20' },
    { id: '79', name: 'F027', coords: '815,215,30,20' },
    { id: '80', name: 'F028', coords: '845,215,30,20' },
    { id: '81', name: 'F029', coords: '815,235,30,20' },
    { id: '82', name: 'F030', coords: '845,235,30,20' },
    { id: '83', name: 'F031', coords: '825,290,30,20' },
    { id: '84', name: 'F032', coords: '855,290,30,20' }
];

export default deskConfig6OG;