import React, { useEffect, useState } from "react";
import "bulma/css/bulma.min.css";
import { Navbar } from "react-bulma-components";
import { NavLink } from "react-router-dom";
import UsersComponent from "./users.component";
import { useMsGraphTokenContext } from '../contexts/MsGraphTokenContextProvider';
import useAxiosWithToken from '../hooks/axiosTokenHook';
import logo from "../images/beyond-logo.png";

function Navigation() {
  const axiosInstance = useAxiosWithToken();
  const [isAdminLinkVisible, setIsAdminLinkVisible] = useState(false);
  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(false); 
  const { msGraphToken, error } = useMsGraphTokenContext();
  const [isCheckingAdminStatus, setIsCheckingAdminStatus] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      setIsCheckingAdminStatus(true);
      if (!msGraphToken) {
        console.log("No msGraphToken, returning");
        setIsCheckingAdminStatus(false);
        return;
      }

      try {
        const response = await axiosInstance.post('/admin', { token: msGraphToken });
        setIsAdminLinkVisible(response.status === 200);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdminLinkVisible(false);
      } finally {
        setIsCheckingAdminStatus(false);
      }
    };

    checkAdminStatus();
  }, [msGraphToken, axiosInstance]);

  const toggleNavbar = () => {
    setIsBurgerMenuActive(!isBurgerMenuActive);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!msGraphToken) {
    return <div>Please log in to access navigation.</div>;
  }

  return (
    <Navbar className="is-primary" role="navigation" aria-label="main navigation">
      <Navbar.Brand>
        <Navbar.Item as={NavLink} to="/app">
          <img src={logo} alt="Logo" />
        </Navbar.Item>
      </Navbar.Brand>
      <div className="navbar-end">
        <Navbar.Burger onClick={toggleNavbar} className={`navbar-burger ${isBurgerMenuActive ? "is-active" : ""} is-hidden-desktop`} />
      </div>

      <Navbar.Menu className={isBurgerMenuActive ? 'is-active' : ''}>
        <Navbar.Container>
          <NavLink to="/app/booking-list" className="navbar-item">My Bookings</NavLink>
          <NavLink to="/app/shareddesk" className="navbar-item">Book shared desk</NavLink>
          <NavLink to="/app/booking-overview" className="navbar-item">Overview</NavLink>
          <NavLink to="/app/booking-overview-advanced" className="navbar-item">Advanced Overview</NavLink>
        </Navbar.Container>

        <Navbar.Container align="right">
          {isCheckingAdminStatus ? (
            <Navbar.Item>
              <span className="loader"></span>
            </Navbar.Item>
          ) : isAdminLinkVisible && (
            <NavLink to="/app/admin-booking" className="navbar-item">Admin Booking</NavLink>
          )}
          <a href="/UserManual.pdf" target="_blank" rel="noopener noreferrer" className="navbar-item no-color-change">User Manual</a>
          <a href="https://teams.microsoft.com/l/channel/19%3A7adbc31c68a741f1802242d02fd01701%40thread.tacv2/SDRT-Feedback?groupId=5e0f30b6-91aa-4c28-a73f-261205ab36ce&tenantId=a00c0469-8b25-4b75-ba6c-e8aa467c9bbe" target="_blank" rel="noopener noreferrer" className="navbar-item no-color-change">Contact Us</a>
          <UsersComponent />
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
}

export default Navigation;
