
const searchUsers = async (searchString, msGraphToken, refreshToken) => {
    try {
        const response = await fetch(`https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchString}') or startswith(mail,'${searchString}')&$select=displayName,mail`, {
            headers: {
                'Authorization': `Bearer ${msGraphToken}`
            }
        });

        if (response.status === 401) {
            console.log("Token expired, refreshing...");
            await refreshToken();
        // Retry the request with the new token
            return searchUsers(searchString, msGraphToken, refreshToken);
        }

        if (!response.ok) {
            throw new Error(`Failed to fetch users: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data.value || [];
    } catch (error) {
        console.error('Error in searchUsers:', error);
        return [];
    }
};

export default searchUsers;
