import React, { createContext, useContext, useReducer, useEffect, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

const MsGraphTokenContext = createContext();

const initialState = {
    msGraphToken: null,
    isLoading: true,
    error: null
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_TOKEN':
            return { ...state, msGraphToken: action.payload, isLoading: false, error: null };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload, isLoading: false };
        case 'CLEAR_TOKEN':
            return { ...state, msGraphToken: null, isLoading: false, error: null };
        default:
            return state;
    }
}

export const MsGraphTokenProvider = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const [state, dispatch] = useReducer(reducer, initialState);

    const acquireToken = useCallback(async (forceRefresh = false) => {
        console.log("Acquiring token...");
        if (inProgress !== "none") {
            console.log("Authentication in progress, skipping token acquisition");
            return;
        }

        if (accounts.length === 0) {
            console.log("No accounts found, clearing token");
            dispatch({ type: 'CLEAR_TOKEN' });
            return;
        }

        try {
            console.log("Attempting to acquire token silently");
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
                forceRefresh
            });
            console.log("Token acquired successfully");
            dispatch({ type: 'SET_TOKEN', payload: response.accessToken });
        } catch (error) {
            console.error("Error acquiring token:", error);
            if (error instanceof InteractionRequiredAuthError) {
                console.log("Interaction required, consider using acquireTokenPopup");
                dispatch({ type: 'SET_ERROR', payload: "Interaction required to acquire token" });
            } else {
                dispatch({ type: 'SET_ERROR', payload: error.message });
            }
        }
    }, [instance, accounts, inProgress]);

    useEffect(() => {
        console.log("MsGraphTokenProvider useEffect", { inProgress });
        if (inProgress === "none") {
            acquireToken();
        } else {
            dispatch({ type: 'SET_LOADING', payload: true });
        }
    }, [acquireToken, inProgress]);

    const refreshToken = useCallback(() => acquireToken(true), [acquireToken]);

    const contextValue = {
        ...state,
        refreshToken
    };

    return (
        <MsGraphTokenContext.Provider value={contextValue}>
            {children}
        </MsGraphTokenContext.Provider>
    );
};

export const useMsGraphTokenContext = () => {
    const context = useContext(MsGraphTokenContext);
    if (context === undefined) {
        throw new Error('useMsGraph must be used within a MsGraphTokenProvider');
    }
    return context || { msGraphToken: null, isLoading: true, error: null };
};
