const deskConfig6OG_A = [
    { id: '1', name: 'D027', coords: '20,195,20,30' },
    { id: '2', name: 'D028', coords: '20,230,20,30' },
    { id: '3', name: 'D025', coords: '35,200,20,30' },
    { id: '4', name: 'D026', coords: '35,230,20,30' },
    { id: '7', name: 'D023', coords: '110,200,20,30' },
    { id: '8', name: 'D024', coords: '110,230,20,30' },
    { id: '9', name: 'D019', coords: '270,200,20,30' },
    { id: '10', name: 'D020', coords: '270,230,20,30' },
    { id: '11', name: 'D017', coords: '290,200,20,30' },
    { id: '12', name: 'D018', coords: '290,230,20,30' },
    { id: '13', name: 'D015', coords: '355,200,20,30' },
    { id: '14', name: 'D016', coords: '355,230,20,30' },
    { id: '15', name: 'D013', coords: '370,185,20,30' },
    { id: '16', name: 'D014', coords: '375,220,20,30' },
    { id: '17', name: 'D009', coords: '355,15,20,30' },
    { id: '18', name: 'D010', coords: '355,45,20,30' },
    { id: '19', name: 'D011', coords: '370,20,20,30' },
    { id: '20', name: 'D012', coords: '370,50,20,30' },
    { id: '21', name: 'D005', coords: '185,10,20,30' },
    { id: '22', name: 'D006', coords: '190,45,20,30' },
    { id: '23', name: 'D007', coords: '205,20,20,30' },
    { id: '24', name: 'D008', coords: '205,55,20,30' },
    { id: '25', name: 'D001', coords: '115,15,20,30' },
    { id: '26', name: 'D002', coords: '115,45,20,30' },
    { id: '27', name: 'D003', coords: '135,15,20,30' },
    { id: '28', name: 'D004', coords: '135,45,20,30' },

]

export default deskConfig6OG_A