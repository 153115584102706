import React from 'react';
import ReactDOM from 'react-dom';

const TooltipPortal = ({ children, isVisible, position, areaClass }) => {
    let tooltipColor = 'black'; // default color

    // Determine tooltip color based on area class
    if (areaClass.includes('free')) {
        tooltipColor = 'green';
    } else if (areaClass.includes('booked')) {
        if (areaClass.includes('own')) {
            tooltipColor = 'blue';
        } else if (areaClass.includes('admin')) {
            tooltipColor = 'magenta';
        } else {
            tooltipColor = 'red';
        }
    }

    // Calculate adjusted position
    const tooltipWidth = 200; 
    const tooltipHeight = 50; 
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the left and top positions
    let tooltipLeft = position.x;
    let tooltipTop = position.y;

    // Adjust if the tooltip overflows to the left
    if (tooltipLeft < 0) {
        tooltipLeft = 10; // 10px padding from the left edge of the viewport
    }

    // Adjust if the tooltip overflows to the right
    if (tooltipLeft + tooltipWidth > viewportWidth) {
        tooltipLeft = viewportWidth - tooltipWidth - 10; // 10px padding from the right edge
    }

    // Adjust vertical position
    if (tooltipTop < 0) {
        tooltipTop = position.y + 10; // Move it down if it overflows above
    } else if (tooltipTop + tooltipHeight > viewportHeight) {
        tooltipTop = position.y - tooltipHeight - 10; // Move it up if it overflows below
    }

    // Final adjustments to ensure it doesn't overlap with the target element
    if (tooltipTop < position.y) {
        tooltipTop = position.y + 10; // Move it below the target if it overlaps
    }

    // Additional check for left overflow
    if ((tooltipLeft - tooltipWidth) < 50) {
        tooltipLeft = position.x + 100; // Move it to the right if it overflows to the left
    }


    return ReactDOM.createPortal(
        <div
            className={`tooltip custom-tooltip tooltip-${tooltipColor} ${isVisible ? 'is-tooltip-active' : ''}`}
            data-tooltip={children || 'Tooltip Content'}
            style={{
                position: 'fixed',
                top: tooltipTop, // Use adjusted top position
                left: tooltipLeft, // Use adjusted left position
                zIndex: 10000,
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.2s',
                pointerEvents: 'none',
                width: '1px',
                height: '1px',
            }}
        >
        </div>,
        document.body
    );
};

export default TooltipPortal;
