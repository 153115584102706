const deskConfig5OG = [
    { id: '89', name: 'A001', coords: '1705,1650,50,100' },
    { id: '90', name: 'A002', coords: '1705,1750,50,100' },
    { id: '91', name: 'A003', coords: '1755,1650,50,100' },
    { id: '92', name: 'A004', coords: '1755,1750,50,100' },
    { id: '93', name: 'A005', coords: '1945,1650,55,115' },
    { id: '94', name: 'A006', coords: '1945,1740,55,115' },
    { id: '95', name: 'A007', coords: '2000,1675,55,115' },
    { id: '96', name: 'A008', coords: '2000,1775,55,115' },
    { id: '97', name: 'A009', coords: '2195,1650,50,100' },
    { id: '98', name: 'A010', coords: '2195,1750,50,100' },
    { id: '99', name: 'A011', coords: '2245,1650,50,100' },
    { id: '100', name: 'A012', coords: '2245,1750,50,100' },
    { id: '101', name: 'A021', coords: '2495,1670,55,115' },
    { id: '102', name: 'A022', coords: '2550,1670,55,115' },
    { id: '103', name: 'A017', coords: '2520,2185,55,115' },
    { id: '104', name: 'A018', coords: '2520,2285,55,115' },
    { id: '105', name: 'A019', coords: '2570,2215,55,115' },
    { id: '106', name: 'A020', coords: '2570,2310,55,115' },
    { id: '107', name: 'A013', coords: '2235,2215,50,100' },
    { id: '108', name: 'A014', coords: '2235,2315,50,100' },
    { id: '109', name: 'A015', coords: '2285,2215,50,100' },
    { id: '110', name: 'A016', coords: '2285,2315,50,100' },
    { id: '111', name: 'B001', coords: '3600,2210,50,100' },
    { id: '112', name: 'B002', coords: '3600,2310,50,100' },
    { id: '113', name: 'B003', coords: '3650,2210,50,100' },
    { id: '114', name: 'B004', coords: '3650,2310,50,100' },
    { id: '115', name: 'B005', coords: '3815,2185,50,100' },
    { id: '116', name: 'B006', coords: '3815,2285,50,100' },
    { id: '117', name: 'B007', coords: '3865,2185,50,100' },
    { id: '118', name: 'B008', coords: '3865,2285,50,100' },
    { id: '119', name: 'B009', coords: '3890,2515,50,100' },
    { id: '120', name: 'B010', coords: '3890,2615,50,100' },
    { id: '121', name: 'B011', coords: '3940,2515,50,100' },
    { id: '122', name: 'B012', coords: '3940,2615,50,100' },
    { id: '123', name: 'B013', coords: '4130,2485,50,100' },
    { id: '124', name: 'B014', coords: '4130,2580,50,100' },
    { id: '125', name: 'B015', coords: '4185,2510,50,100' },
    { id: '126', name: 'B016', coords: '4180,2605,50,100' },
    { id: '127', name: 'B018', coords: '4371,2295,100,50' },
    { id: '128', name: 'B019', coords: '4485,2290,100,50' },
    { id: '129', name: 'B022', coords: '4370,2055,100,50' },
    { id: '130', name: 'B023', coords: '4470,2050,100,50' },
    { id: '131', name: 'B020', coords: '4380,2100,100,50' },
    { id: '132', name: 'B021', coords: '4475,2100,100,50' },
    { id: '133', name: 'C003', coords: '4380,1760,100,50' },
    { id: '134', name: 'C004', coords: '4480,1765,100,50' },
    { id: '135', name: 'C001', coords: '4380,1815,100,50' },
    { id: '136', name: 'C002', coords: '4485,1815,100,50' },
    { id: '137', name: 'C007', coords: '4385,1085,100,50' },
    { id: '138', name: 'C008', coords: '4485,1095,100,50' },
    { id: '139', name: 'C005', coords: '4380,1150,100,50' },
    { id: '140', name: 'C006', coords: '4480,1140,100,50' },
    { id: '141', name: 'C011', coords: '4350,590,100,50' },
    { id: '142', name: 'C012', coords: '4455,595,100,50' },
    { id: '143', name: 'C009', coords: '4340,635,100,50' },
    { id: '144', name: 'C010', coords: '4445,645,100,50' },
    { id: '145', name: 'C014', coords: '4230,170,50,100' },
    { id: '147', name: 'C015', coords: '4285,170,50,100' },
    { id: '149', name: 'C018', coords: '3810,505,100,50' },
    { id: '150', name: 'C019', coords: '3915,505,100,50' },
    { id: '151', name: 'C016', coords: '3850,555,100,50' },
    { id: '152', name: 'C017', coords: '3950,560,100,50' }
];

export default deskConfig5OG;