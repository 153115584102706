const deskConfig6OG_B = [
    { id: '31', name: 'E001', coords: '35,80,20,35' },
    { id: '32', name: 'E002', coords: '35,115,20,35' },
    { id: '33', name: 'E003', coords: '95,70,20,35' },
    { id: '34', name: 'E004', coords: '95,105,20,35' },
    { id: '35', name: 'E005', coords: '115,80,20,35' },
    { id: '36', name: 'E006', coords: '115,110,20,35' },
    { id: '37', name: 'E007', coords: '205,170,20,35' },
    { id: '38', name: 'E008', coords: '210,200,20,35' },
    { id: '39', name: 'E009', coords: '225,180,20,35' },
    { id: '40', name: 'E010', coords: '225,210,20,35' },
    { id: '41', name: 'E011', coords: '290,180,20,30' },
    { id: '42', name: 'E012', coords: '290,210,20,30' },
    { id: '43', name: 'E013', coords: '310,180,20,30' },
    { id: '44', name: 'E014', coords: '310,210,20,30' },
    { id: '45', name: 'E017', coords: '280,100,35,15' },
    { id: '46', name: 'E018', coords: '310,100,35,15' },
    { id: '47', name: 'E015', coords: '270,120,35,15' },
    { id: '48', name: 'E016', coords: '305,120,35,15' },
    { id: '49', name: 'E021', coords: '280,25,35,15' },
    { id: '50', name: 'E022', coords: '315,25,35,15' },
    { id: '51', name: 'E019', coords: '280,40,35,15' },
    { id: '52', name: 'E020', coords: '315,40,35,15' }
]

export default deskConfig6OG_B