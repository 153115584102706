import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useMsGraphTokenContext } from '../contexts/MsGraphTokenContextProvider';
import useAxiosWithToken from '../hooks/axiosTokenHook';
import { Progress } from 'react-bulma-components';


const ProtectedRoute = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(null);
    const { msGraphToken, isLoading } = useMsGraphTokenContext();
    const axiosInstance = useAxiosWithToken();

    useEffect(() => {
        const verifyAdminStatus = async () => {
            if (!msGraphToken) {
                console.log("No msGraphToken, returning");
                return;
            }

            try {
                const response = await axiosInstance.post('/admin', {
                    token: msGraphToken,
                });
                setIsAdmin(response.status === 200);
            } catch (error) {
                console.error('Error verifying admin status:', error);
                setIsAdmin(false);
            }
        };

        verifyAdminStatus();
    }, [msGraphToken, axiosInstance]);

    if (isLoading || isAdmin === null) {
        return <Progress />;
    }

    if (!isAdmin) {
        return <Navigate to="/app" replace />;
    }

    return children;
};

export default ProtectedRoute;
