const deskConfig6OG_C = [
    { id: '53', name: 'F003', coords: '195,535,30,20' },
    { id: '54', name: 'F004', coords: '230,535,30,20' },
    { id: '55', name: 'F001', coords: '195,550,30,20' },
    { id: '56', name: 'F002', coords: '230,550,30,20' },
    { id: '57', name: 'F007', coords: '195,455,30,20' },
    { id: '58', name: 'F008', coords: '230,455,30,20' },
    { id: '59', name: 'F005', coords: '195,470,30,20' },
    { id: '60', name: 'F006', coords: '230,470,30,20' },
    { id: '61', name: 'F011', coords: '185,265,30,20' },
    { id: '62', name: 'F012', coords: '220,265,30,20' },
    { id: '63', name: 'F009', coords: '195,280,30,20' },
    { id: '64', name: 'F010', coords: '230,280,30,20' },
    { id: '65', name: 'F013', coords: '195,190,30,20' },
    { id: '66', name: 'F014', coords: '230,190,30,20' },
    { id: '67', name: 'F017', coords: '195,110,30,20' },
    { id: '68', name: 'F018', coords: '230,110,30,20' },
    { id: '69', name: 'F015', coords: '185,130,30,20' },
    { id: '70', name: 'F016', coords: '220,130,30,20' },
    { id: '71', name: 'F021', coords: '195,35,30,20' },
    { id: '72', name: 'F022', coords: '230,35,30,20' },
    { id: '73', name: 'F019', coords: '195,55,30,20' },
    { id: '74', name: 'F020', coords: '230,55,30,20' },
    { id: '75', name: 'F023', coords: '5,115,30,20' },
    { id: '76', name: 'F024', coords: '40,115,30,20' },
    { id: '77', name: 'F025', coords: '15,130,30,20' },
    { id: '78', name: 'F026', coords: '50,135,30,20' },
    { id: '79', name: 'F027', coords: '10,190,30,20' },
    { id: '80', name: 'F028', coords: '40,190,30,20' },
    { id: '81', name: 'F029', coords: '10,210,30,20' },
    { id: '82', name: 'F030', coords: '40,210,30,20' },
    { id: '83', name: 'F031', coords: '20,270,30,20' },
    { id: '84', name: 'F032', coords: '50,270,30,20' }
];

export default deskConfig6OG_C;