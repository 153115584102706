import React, { useMemo, useRef, useState, useEffect } from 'react';
import { formatDateISO } from '../../utils/utils';
import TooltipPortal from '../../utils/TooltipPortal';


const FloorplanBase = ({ imgSrc, deskConfig, bookings, handleAreaClick, selectedArea, user, date }) => {
    const containerRef = useRef(null);
    const imgRef = useRef(null); // Add a reference to the image
    const [scale, setScale] = useState({ x: 1, y: 1 });
    const [selectedDesk, setSelectedDesk] = useState(null);
    const selectedDate = formatDateISO(date); 



    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current && imgRef.current) {
                const img = imgRef.current;
                setScale({
                    x: img.clientWidth / img.naturalWidth,
                    y: img.clientHeight / img.naturalHeight
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleImageLoad = () => {
        if (containerRef.current && imgRef.current) {
            const img = imgRef.current;
            setScale({
                x: img.clientWidth / img.naturalWidth,
                y: img.clientHeight / img.naturalHeight
            });
        }
    };

    const tooltipText = (booking, isBooked, desk) => {
        return isBooked ? desk.name + ` -> Booked by ${booking.username}` : desk.name + ' -> Desk available';
    };

    const [tooltipData, setTooltipData] = useState({
        isVisible: false,
        content: '',
        position: { x: 0, y: 0 },
        areaClass: ''
    });

    const handleMouseEnter = (event, tooltipContent, areaClass) => {
        const rect = event.target.getBoundingClientRect();
        setTooltipData({
            isVisible: true,
            content: tooltipContent,
            position: { x: rect.left + rect.width / 2, y: rect.top },
            areaClass: areaClass
        });
    };

    const handleMouseLeave = () => {
        setTooltipData(prevData => ({ ...prevData, isVisible: false }));
    };

    const FloorPlanComponent = useMemo(() => {
        const deskBookings = new Map(bookings.filter(booking => booking.id)
            .filter(booking => booking.date == formatDateISO(selectedDate)) // Use `selectedDate` here
            .map(booking => [String(booking.deskid), booking]));

        return (
            <div ref={containerRef} className="floor-plan-container">
                <img
                    ref={imgRef} // Assign the reference to the image
                    src={imgSrc}
                    alt="Floor Plan"
                    className="floor-plan-image"
                    onLoad={handleImageLoad}
                />
                <div className="areas">
                    {deskConfig.map((desk) => {
                        const [x, y, width, height] = desk.coords.split(',').map(Number);
                        const booking = deskBookings.get(String(desk.id));
                        const isBooked = !!booking;
                        const isOwnBooking = booking && booking.username === user;

                        let areaClass = isBooked ? 'booked' : 'free';
                        if (isBooked) {
                            if (booking.isAdmin === 0 && isOwnBooking) {
                                areaClass += ' own';
                            } else if (booking.isAdmin === 1) {
                                areaClass += ' admin';
                            }
                        }

                        if (selectedDesk && selectedDesk.id === desk.id) {
                            areaClass += ' selected';
                        }

                        return (
                            <div
                                key={desk.id}
                                className={`area ${areaClass} ${selectedArea === desk.id ? 'selected' : ''}`}
                                id={desk.id}
                                style={{
                                    top: `${y * scale.y}px`,
                                    left: `${x * scale.x}px`,
                                    width: `${width * scale.x}px`,
                                    height: `${height * scale.y}px`,
                                    position: 'absolute',
                                    boxSizing: 'border-box'
                                }}
                                onClick={() => {
                                    handleAreaClick(desk);
                                    setSelectedDesk(desk);
                                }}
                                onMouseEnter={(e) => handleMouseEnter(e, tooltipText(booking, isBooked, desk), areaClass)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {desk.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [bookings, selectedArea, scale, selectedDesk, handleAreaClick, user, deskConfig, selectedDate]);

    return (
        <>
            {FloorPlanComponent}
            <TooltipPortal
                isVisible={tooltipData.isVisible}
                position={tooltipData.position}
                areaClass={tooltipData.areaClass}
            >
                {tooltipData.content}
            </TooltipPortal>
        </>
    );
};

export default FloorplanBase;
