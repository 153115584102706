const deskConfig5OG_B = [
    { id: '111', name: 'B001', coords: '100,600,50,100' },
    { id: '112', name: 'B002', coords: '100,700,50,100' },
    { id: '113', name: 'B003', coords: '150,600,50,100' },
    { id: '114', name: 'B004', coords: '150,700,50,100' },
    { id: '115', name: 'B005', coords: '315,570,50,100' },
    { id: '116', name: 'B006', coords: '315,670,50,100' },
    { id: '117', name: 'B007', coords: '365,570,50,100' },
    { id: '118', name: 'B008', coords: '365,670,50,100' },
    { id: '119', name: 'B009', coords: '400,890,50,100' },
    { id: '120', name: 'B010', coords: '400,990,50,100' },
    { id: '121', name: 'B011', coords: '450,890,50,100' },
    { id: '122', name: 'B012', coords: '450,990,50,100' },
    { id: '123', name: 'B013', coords: '635,865,50,100' },
    { id: '124', name: 'B014', coords: '635,965,50,100' },
    { id: '125', name: 'B015', coords: '690,900,50,100' },
    { id: '126', name: 'B016', coords: '690,1000,50,100' },
    { id: '127', name: 'B018', coords: '885,680,100,50' },
    { id: '128', name: 'B019', coords: '990,680,100,50' },
    { id: '129', name: 'B022', coords: '890,435,100,50' },
    { id: '130', name: 'B023', coords: '990,435,100,50' },
    { id: '131', name: 'B020', coords: '890,490,100,50' },
    { id: '132', name: 'B021', coords: '990,490,100,50' }
]

export default deskConfig5OG_B